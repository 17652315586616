





























































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import {Getter, namespace} from 'vuex-class';
import { ICalculatedValues, IProduct, IStore, IUser, PayloadState } from '@/types/types';

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required } from 'vee-validate/dist/rules';
import { debounce } from 'lodash';
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Field accept only numbers',
});
const productsModule = namespace('products');
const customersModule = namespace('customers');
const authModule = namespace('auth');

@Component({
  name: 'TopUpAccountDialog',
  components: {
    ValidationObserver,
    ValidationProvider,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class TopUpAccountDialog extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @customersModule.Getter('getCalculatedValues') calculatedValues!: ICalculatedValues;
  @productsModule.Getter('getProductDetails') details!: IProduct;
  @authModule.Getter('getUserDetails') userDetails!: IUser & IStore;
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  topUpType: Array<{ text: string; value: string }> = [
    {
      text: 'Currency',
      value: 'CURRENCY',
    },
    {
      text: 'Credit',
      value: 'UNITS',
    },
  ];
  type: string = 'CURRENCY';
  amount: number | null = null;

  amountChange(text: number): void {
    if (this.type) this.$store.dispatch('customers/smsCalculator', `type=${this.type}&amount=${text}`);
  }
  handleOnAmountChange = debounce(this.amountChange, 1000);

  @Watch('amount')
  onTopUpAmountChange(value: number): void {
    this.handleOnAmountChange(Number(value));
  }

  typeChange(text: string): void {
    if (this.amount) this.$store.dispatch('customers/smsCalculator', `type=${text}&amount=${this.amount}`);
  }
  handleOnTypeChange = debounce(this.typeChange, 1000);

  @Watch('type')
  onTopUpTypeChange(text: string): void {
    this.handleOnTypeChange(text);
  }

  @Watch('resetFormState')
  onFormChange(payload: boolean) {
    if (payload) {
      this.amount = null;
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Emit('topUpAccount')
  topUpAccount() {
    return {
      ...this.calculatedValues.query,
      amount: Number(this.calculatedValues.query?.amount),
      calculatedValue: this.type === 'UNITS' ? Number(this.amount) : Number(this.calculatedValues.totalCreditUnits)
    };
  }

  @Emit('actions')
  close(): PayloadState {
    this.amount = null;
    return {
      idx: 'add',
      state: false,
    };
  }
}
